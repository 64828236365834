import React, { useState } from "react";
import NCALayerService from "../service/NCALayerService";
import SDKUserFormService from "../service/SDKUserFormService";
import { useNavigate } from "react-router-dom";
import AuthenticationService from "../service/AuthenticationService";
import { useTranslation } from "react-i18next";
import config from "../config";
import ErrorComponent from "./ErrorComponent";
const AuthenticationComponent = () => {
  const { t } = useTranslation();
  const [isChecked, setChecked] = useState(false);
  const navigate = useNavigate();
  const [userError, setUserError] = useState(null);

  // const [keySettings, setKeySettings] = useState('');

  const xmlString = `<root>

    </root>`;
  //     <KeySettingsDigiSign>
  //     <uuid>${keySettings.uuid}</uuid>
  //     <date>${keySettings.date}</date>
  // </KeySettingsDigiSign>
  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  // const handleSubmit = async (e) => {
  //     e.preventDefault();
  //     try {
  //         const result = await NCALayerService.sign(xmlString, '1.3.6.1.5.5.7.3.2');

  //         const userResponse = await AuthenticationService.getUser(result);
  //         if (userResponse && userResponse.data !== undefined) {
  //             console.log(userResponse.data);
  //             navigate("/update-email", { replace: true, state: { userResponseData: userResponse.data } });
  //         } else if (userResponse.status === 404) {
  //             setUserError(t('error.informationSystemNotFoundError'));
  //         } else if (userResponse.status === 404) {
  //             setUserError(t('error.invalidSignatureError'));
  //         } else {
  //             navigate('/error', {replace: true,
  //                 state: {
  //                     error: t('error.serverError'),
  //                 },
  //             });
  //         }
  //     } catch (error) {

  //         console.error(error);
  //         navigate('/error', {replace: true,
  //             state: {
  //                 error: t('error.ncalayer'),
  //             },
  //         });
  //     }

  // };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const result = await NCALayerService.sign(xmlString, "1.3.6.1.5.5.7.3.2");
      if (result == undefined) {
        navigate("/error", {
          replace: true,
          state: { error: t("error.ncalayer") },
        });
      } else {
        const userResponse = await AuthenticationService.getUser(result);
        if (userResponse && userResponse.data !== undefined) {
          console.log(userResponse.data);
          navigate("/update-email", {
            replace: true,
            state: { userResponseData: userResponse.data },
          });
        } else {
          navigate("/error", {
            replace: true,
            state: { error: t("error.serverError") },
          });
        }
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 404) {
          setUserError(t("error.userNotFoundError"));
        } else if (
          error.response.status === 400 &&
          error.response.data.includes("Invalid signature")
        ) {
          setUserError(t("error.invalidSignatureError"));
        } else {
          console.error("Другая ошибка:", error.message);
          navigate("/error", {
            replace: true,
            state: { error: t("error.serverError") },
          });
        }
      } else {
        console.error(error);
        navigate("/error", {
          replace: true,
          state: { error: t("error.ncalayer") },
        });
      }
    }
  };

  return (
    <div className="content">
      <div className=" titleCell">
        <span>{t("authentication.title")}</span>
      </div>

      <form
        id="indexForm"
        name="indexForm"
        method="post"
        action="/ua/login.xhtml"
        encType="application/x-www-form-urlencoded"
        onSubmit={handleSubmit}
      >
        <input type="hidden" name="indexForm" value="indexForm" />

        <div
          id="msg"
          className="ui-messages ui-widget"
          aria-live="polite"
        ></div>

        <div className="auth-frame">
          <div className="auth-warning-block">
            <div className="auth-warning-block__title">
              <span id="agrTitle">{t("authentication.authWarningTitle")}</span>
            </div>
            <div className="auth-warning-block__body">
              <span id="agrText">{t("authentication.authWarning")}</span>
            </div>
            <div className="auth-warning-block__check">
              <input
                id="j_idt33"
                type="checkbox"
                name="j_idt33"
                className="checkboxConfirmation"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              {t("authentication.checkBox")}
            </div>
          </div>
          {userError && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p style={{ color: "red", textAlign: "center" }}>{userError}</p>
            </div>
          )}
          <button
            id="testSubmitButton"
            name="testSubmitButton"
            className="ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only ui-state-disabled new-blue-btn"
            onClick={handleSubmit}
            type="submit"
            disabled={!isChecked}
            role="button"
            aria-disabled="true"
          >
            <span className="ui-button-text ui-c">
              {t("authentication.button")}
            </span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default AuthenticationComponent;
