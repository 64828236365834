import React from 'react';
import ReactDOM from 'react-dom/client';
import './i18n';
import App from './App';
// import reportWebVitals from './reportWebVitals';

import './style.css';

import 'bootstrap/dist/css/bootstrap.min.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />

);

// reportWebVitals();
