
    const domain = window.location.origin;
    const API_JAVA = `${domain}/sdk-user`;
   const API_PKI = "https://pki.gov.kz/";


   // const API_JAVA = "http://localhost:8080/sdk-user";
   // const API_PKI = "http://localhost/new_www";
export {API_JAVA, API_PKI};


