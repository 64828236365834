import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const UserSelectorComponent = () => {

   const { t } = useTranslation();


   return (
      <div className="content">

         <div className=" titleCell">
            <span>{t('userSelector.userSelectorTitle')}</span>
         </div>
         <br></br>
         <div className="selectorContent">
            <Link to="/fl-check"><button
               id="btn-sel"
               name="rcfield:0:checkCompanyButton"
               className="new-blue-btn-sel"
               type="submit"
               role="button"
               aria-disabled="false"
            >
               <span className="ui-button-text ui-c">{t('userSelector.flButton')}</span>
            </button></Link>
            <Link to="/ul-check"> <button
               id="btn-sel"
               name="rcfield:0:checkCompanyButton"
               className="new-blue-btn-sel"
               type="submit"
               role="button"
               aria-disabled="false"
            >
               <span className="ui-button-text ui-c">{t('userSelector.ulButton')}</span>
            </button></Link>

         </div>
      </div>
   );
};


export default UserSelectorComponent;

// import React, { useState } from 'react';
// import { Navigate } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';

// const UserSelectorComponent = () => {
//    const { t } = useTranslation();
//    const [navigateTo, setNavigateTo] = useState(null);

//    if (navigateTo) {
//       return <Navigate to={navigateTo} replace={true} />;
//    }

//    return (
//       <div className="content">
//          <div className="titleCell">
//             <span>{t('userSelector.userSelectorTitle')}</span>
//          </div>
//          <br />
//          <div className="selectorContent">
//             <button
//                id="btn-sel"
//                name="rcfield:0:checkCompanyButton"
//                className="new-blue-btn-sel"
//                type="button"
//                role="button"
//                aria-disabled="false"
//                onClick={() => setNavigateTo('/fl-check')}
//             >
//                <span className="ui-button-text ui-c">{t('userSelector.flButton')}</span>
//             </button>
//             <button
//                id="btn-sel"
//                name="rcfield:0:checkCompanyButton"
//                className="new-blue-btn-sel"
//                type="button"
//                role="button"
//                aria-disabled="false"
//                onClick={() => setNavigateTo('/ul-check')}
//             >
//                <span className="ui-button-text ui-c">{t('userSelector.ulButton')}</span>
//             </button>
//          </div>
//       </div>
//    );
// };

// export default UserSelectorComponent;
