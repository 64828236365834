import React, { useState, useEffect } from "react";
import NCALayerService from "../service/NCALayerService";
import SDKUserFormService from "../service/SDKUserFormService";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import ErrorModal from "./ErrorModal";

const SDKUserEmailUpdateComponent = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const { userResponseData } = location.state;

  const [validatedBIN, setValidatedBIN] = useState("");
  const [validatedOrganizationName, setValidatedOrganizationName] =
    useState("");
  const [signerFio, setSignerFio] = useState("");

  const [iin, setIin] = useState("");
  const [fio, setFio] = useState("");
  const [bin, setBin] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const [informationSystemName, setInformationSystemName] = useState("");
  const [selectedInformationSystem, setSelectedInformationSystem] =
    useState(null);
  const [informationSystemOptions, setInformationSystemOptions] = useState([]);

  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isInputFocusedEmail, setIsInputFocusedEmail] = useState(false);

  const [personalPhoneNumber, setPersonalPhoneNumber] = useState("+7");
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);
  const [isInputFocusedPhoneNumber, setIsInputFocusedPhoneNumber] =
    useState(false);

  const [userCount, setUserCount] = useState("");
  const [id, setId] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);

  const xmlString = `<root>
  <id>${id}</id>
    <bin>${validatedBIN || bin}</bin>
     <organizationName>${escapeXml(
       validatedOrganizationName || organizationName
     )}</organizationName>
         <informationSystemName>${escapeXml(
           informationSystemName
         )}</informationSystemName>
    <iin>${iin}</iin>
    <fio>${signerFio || fio}</fio>
    <personalPhoneNumber>${personalPhoneNumber}</personalPhoneNumber>
    <email>${email}</email>
    <userCount>${userCount}</userCount>
</root>`;
  const navigate = useNavigate();

  function escapeXml(unsafe) {
    return unsafe.replace(/[&<>"']/g, function (match) {
      switch (match) {
        case "&":
          return "&amp;";
        case "<":
          return "&lt;";
        case ">":
          return "&gt;";
        case '"':
          return "&quot;";
        case "'":
          return "&apos;";
        default:
          return match;
      }
    });
  }
  const iinRegex = /^\d{12}$/;
  const handleSign = async (e) => {
    e.preventDefault();
    if (userResponseData[0].iin && userResponseData[0].bin) {
      if (
        iin.trim() === "" ||
        informationSystemName.trim() === "" ||
        !isValidPhoneNumber ||
        personalPhoneNumber == "+7" ||
        !isValidEmail ||
        email == "" ||
        userCount.trim() === "" ||
        userCount.trim() === 0 ||
        !iinRegex.test(iin)
      ) {
        setErrorMessage(t("error.correctInputError"));
        return;
      }
    } else if (userResponseData[0].iin && !userResponseData[0].bin) {
      setIin(userResponseData[0].iin);
      if (
        informationSystemName.trim() === "" ||
        !isValidPhoneNumber ||
        personalPhoneNumber == "+7" ||
        !isValidEmail ||
        email == "" ||
        userCount.trim() === "" ||
        userCount.trim() === "0" ||
        !iinRegex.test(iin)
      ) {
        setErrorMessage(t("error.correctInputError"));
        return;
      }
    }

    try {
      console.log("Подпись с NCALayer");
      console.log("id" + userResponseData[0].id);
      const result = await NCALayerService.sign(xmlString, "1.3.6.1.5.5.7.3.4");

      console.log("id:" + id);
      await SDKUserFormService.save(result);

      console.log("Успешный запрос на бэкенд");
      navigate("/success", { replace: true });
    } catch (error) {
      // 27.05.2024

      // console.error('Ошибка при подписи');
      // if (error.response && error.response.status === 401) {
      //     setErrorMessage(t('error.invalidSignatureError'));
      // } else if (error.response && error.response.status === 404 && error.response.data.includes('Invalid Signature')) {
      //     setErrorMessage(t('error.invalidSignatureError'));
      // }
      // else {
      //     navigate('/error', {
      //         replace: true,
      //         state: {
      //             error: error.response.data || '',
      //         },
      //     });
      // }
      console.error("Произошла ошибка:", error);
      if (
        error.response &&
        error.response.status === 404 &&
        error.response.data.includes("foreign.key")
      ) {
        setErrorMessage(t("error.foreignKeyError"));
        console.error("Используется чужая подпись");
      } else if (error.response && error.response.status === 404) {
        setErrorMessage(t("error.invalidSignatureError"));
        console.error("Ошибка: 404 invalidSignatureError");
      } else {
        // Обработка других случаев ошибок
        console.error("Другая ошибка:", error.message);
        navigate("/error", { replace: true });
      }
    }
  };
  //////////////////////////////////
  useEffect(() => {
    setValidatedBIN(userResponseData[0].bin);
    // setValidatedIIN(userResponseData[0].iin);
    setSignerFio(userResponseData[0].signerFio);
    setValidatedOrganizationName(userResponseData[0].organizationName);

    const mappedOptions = userResponseData.map((option) => ({
      value: option.informationSystemName,
      label: option.informationSystemName,
      data: option,
    }));
    setInformationSystemOptions(mappedOptions);
  }, [userResponseData]);

  const handleInformationSystemChange = (selectedOption) => {
    setSelectedInformationSystem(selectedOption);
    const selectedData = selectedOption.data || {};
    setInformationSystemName(selectedData.informationSystemName);
    setId(selectedData.id);
    setIin(selectedData.iin);
    setPersonalPhoneNumber(selectedData.phoneNumber);
    setEmail(selectedData.email);
    setUserCount(selectedData.userCount);
  };

  //////////////email////////////////////
  const validateEmail = (inputEmail) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(inputEmail);
    setIsValidEmail(isValid);
    return isValid;
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    if (isInputFocusedEmail) {
      validateEmail(newEmail);
    }
  };
  const handleInputFocus = () => {
    setIsInputFocusedEmail(true);
  };

  const handleInputBlur = () => {
    setIsInputFocusedEmail(false);
    validateEmail(email);
  };
  // //////////////////////////////////////////////////////////
  const validatePhoneNumber = (inputNumber) => {
    const phoneNumberRegex = /^(\+7|8)?\d{10}$/;
    const isValid = phoneNumberRegex.test(inputNumber);
    setIsValidPhoneNumber(isValid);
    return isValid;
  };

  const handlePersonalPhoneChange = (e) => {
    const inputValue = e.target.value;
    setPersonalPhoneNumber(inputValue);
    if (isInputFocusedPhoneNumber) {
      validatePhoneNumber(inputValue);
    }
  };

  const handleInputFocusPhoneNumber = () => {
    setIsInputFocusedPhoneNumber(true);
  };

  const handleInputBlurPhoneNumber = () => {
    setIsInputFocusedPhoneNumber(false);
    validatePhoneNumber(personalPhoneNumber);
  };
  /////////////////////
  const handleUserCountChange = (e) => {
    const inputValue = e.target.value;

    const onlyNumbers = inputValue.replace(/[^\d]/g, "");
    setUserCount(onlyNumbers);
  };
  ////////////////

  const handleIinChange = (e) => {
    const inputValue = e.target.value;

    const iin12 = inputValue.replace(/[^0-9]/g, "");

    setIin(iin12);
  };

  ///////////Стили для react-select/////////////////
  const customStylesIS = {
    control: (provided, state) => ({
      ...provided,
      width: "450px", // Установка ширины
      minHeight: "26px", // Установка минимальной высоты
      borderRadius: "0px",
      "&:hover": {
        borderColor: "#34769a",
      },
      border: state.isFocused ? "1px solid #34769a" : "1px solid #ccc",
      boxShadow: state.isFocused ? "1px solid #34769a" : "1px solid #ccc",
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: "26px", // Установка высоты

      padding: "0 2px", // Добавление внутренних отступов (если необходимо)
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: "26px", // Установка высоты
    }),

    menu: (provided, state) => ({
      ...provided,

      width: "450px", // Установка ширины развернутого списка
      borderRadius: "0px", // Установка радиуса углов развернутого списка
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Тень развернутого списка
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#34769a" : "white", // Цвет фона при фокусе
      color: state.isFocused ? "white" : "#333",
    }),
  };

  return (
    <div className="content">
      <div className="titleCell">
        <span>{t("SDKUserForm.titleUpdate")}</span>
      </div>

      <form id="indexForm" className="indexForm">
        <div className="vktHeaderDiv">{t("SDKUserForm.formTitle")}</div>
        {errorMessage && <ErrorModal errorMessage={errorMessage} />}
        <table style={{ width: "100%" }}>
          <tbody>
            {userResponseData[0].iin && userResponseData[0].bin && (
              <tr>
                <td>
                  <table cellPadding="8" style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <td className="ccLeft width-255">
                          <span id="rcfield:0:labels">
                            {t("SDKUserForm.bin")}
                            <span style={{ color: "red" }}> *</span>
                          </span>
                        </td>
                        <td className="ccRight width-525">
                          <span id="rcfield:0:values">
                            <input
                              id="rcfield:0:inputValue"
                              name="rcfield:0:inputValue"
                              type="text"
                              maxLength="12"
                              className="ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all width-450 bin"
                              role="textbox"
                              aria-disabled="false"
                              aria-readonly="false"
                              placeholder={t(
                                "SDKUserForm.mandatoryPlaceholder"
                              )}
                              value={validatedBIN || bin}
                              onChange={(e) => setBin(e.target.value)}
                              disabled={!!validatedBIN} // Заблокируйте поле, если validatedBIN существует
                            />
                          </span>
                        </td>
                        <td className="ccRight">
                          <span id="rcfield:5:additionals"></span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            )}

            {userResponseData[0].iin && !userResponseData[0].bin && (
              <tr>
                <td>
                  <table cellPadding="8" style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <td className="ccLeft width-255">
                          <span id="rcfield:0:labels">
                            {t("SDKUserForm.iin")}
                            <span style={{ color: "red" }}> *</span>
                          </span>
                        </td>
                        <td className="ccRight width-525">
                          <span id="rcfield:0:values">
                            <input
                              id="rcfield:0:inputValue"
                              name="rcfield:0:inputValue"
                              type="text"
                              maxLength="12"
                              className="ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all width-450 bin"
                              role="textbox"
                              aria-disabled="false"
                              aria-readonly="false"
                              placeholder={t(
                                "SDKUserForm.mandatoryPlaceholder"
                              )}
                              value={userResponseData[0].iin}
                              onChange={handleIinChange}
                              disabled={!!userResponseData[0].iin}
                            />
                          </span>
                        </td>
                        <td className="ccRight">
                          <span id="rcfield:5:additionals"></span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            )}

            {userResponseData[0].iin && userResponseData[0].bin && (
              <tr>
                <td>
                  <table cellPadding="8" style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <td className="ccLeft width-255">
                          <span id="rcfield:0:labels">
                            {t("SDKUserForm.organizationName")}
                            <span style={{ color: "red" }}> *</span>
                          </span>
                        </td>
                        <td className="ccRight width-525">
                          <span id="rcfield:0:values">
                            <input
                              id="rcfield:0:inputValue"
                              name="rcfield:0:inputValue"
                              type="text"
                              maxLength="255"
                              className="ui-no-auto ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all width-450 bin"
                              role="textbox"
                              aria-disabled="false"
                              aria-readonly="false"
                              placeholder={t(
                                "SDKUserForm.mandatoryPlaceholder"
                              )}
                              value={
                                validatedOrganizationName || organizationName
                              }
                              onChange={(e) =>
                                setOrganizationName(
                                  e.target.value || validatedOrganizationName
                                )
                              }
                              disabled={!!validatedOrganizationName}
                            />
                          </span>
                        </td>
                        <td className="ccRight">
                          <span id="rcfield:5:additionals"></span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            )}
            {/* <tr>
              <td>
                <table cellPadding="8" style={{ width: "100%" }}>
                  <tbody>
                    <tr>
                      <td className="ccLeft width-255">
                        <span id="rcfield:0:labels">
                          {t("SDKUserForm.informationSystemNameOwner")}
                          <span style={{ color: "red" }}> *</span>
                        </span>
                      </td>
                      <td className="ccRight width-525">
                        <span id="rcfield:0:values">
                          <input
                            id="rcfield:0:inputValue"
                            name="rcfield:0:inputValue"
                            type="text"
                            maxLength="255"
                            className="ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all width-450 bin"
                            role="textbox"
                            aria-disabled="false"
                            aria-readonly="false"
                            placeholder={t("SDKUserForm.mandatoryPlaceholder")}
                            value={signerFio || fio}
                            onChange={(e) => setFio(e.target.value)}
                            disabled={!!setSignerFio}
                          />
                        </span>
                      </td>
                      <td className="ccRight">
                        <span id="rcfield:5:additionals"></span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr> */}
            <tr>
              <td>
                <table cellPadding="8" style={{ width: "100%" }}>
                  <tbody>
                    <tr>
                      <td className="ccLeft width-255">
                        <span id="rcfield:0:labels">
                          {t("SDKUserForm.informationSystem")}
                          <span style={{ color: "red" }}> *</span>
                        </span>
                      </td>
                      <td className="ccRight width-525">
                        <span id="rcfield:0:values">
                          <Select
                            value={selectedInformationSystem}
                            onChange={handleInformationSystemChange}
                            options={informationSystemOptions}
                            isSearchable={true}
                            placeholder={t("SDKUserForm.selectIS")}
                            styles={customStylesIS}
                          />
                        </span>
                      </td>
                      <td className="ccRight">
                        <span id="rcfield:5:additionals"></span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            {userResponseData[0].iin && userResponseData[0].bin && (
              <tr>
                <td>
                  <table cellPadding="8" style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <td className="ccLeft width-255">
                          <span id="rcfield:0:labels">
                            {t("SDKUserForm.iin")}
                            <span style={{ color: "red" }}> *</span>
                          </span>
                        </td>
                        <td className="ccRight width-525">
                          <span id="rcfield:0:values">
                            <input
                              id="rcfield:0:inputValue"
                              name="rcfield:0:inputValue"
                              type="text"
                              maxLength="12"
                              className="ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all width-450 bin no-disable-field"
                              role="textbox"
                              aria-disabled="false"
                              aria-readonly="false"
                              placeholder={t(
                                "SDKUserForm.mandatoryPlaceholder"
                              )}
                              value={iin}
                              onChange={handleIinChange}
                            />
                          </span>
                        </td>
                        <td className="ccRight">
                          <span id="rcfield:5:additionals"></span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            )}
            <tr>
              <td>
                <table cellPadding="8" style={{ width: "100%" }}>
                  <tbody>
                    <tr>
                      <td className="ccLeft width-255">
                        <span id="rcfield:0:labels">
                          {t("SDKUserForm.phoneNumber")}
                          <span style={{ color: "red" }}> *</span>
                        </span>
                      </td>
                      <td className="ccRight width-525">
                        <span id="rcfield:0:values">
                          <input
                            id="rcfield:0:inputValue"
                            name="rcfield:0:inputValue"
                            type="text"
                            maxLength="12"
                            pattern="\d*"
                            className="ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all width-450 bin no-disable-field"
                            role="textbox"
                            aria-disabled="false"
                            aria-readonly="false"
                            placeholder="+7(_ _ _) _ _ _ - _ _ - _ _"
                            value={personalPhoneNumber}
                            onChange={handlePersonalPhoneChange}
                            onFocus={handleInputFocusPhoneNumber}
                            onBlur={handleInputBlurPhoneNumber}
                          />
                        </span>
                        {!isValidPhoneNumber &&
                          !isInputFocusedPhoneNumber &&
                          (personalPhoneNumber !== "" ||
                            personalPhoneNumber === "+7") && (
                            <div className="error-message">
                              {t("SDKUserForm.errorPhoneNumber")}
                            </div>
                          )}
                      </td>
                      <td className="ccRight">
                        <span id="rcfield:5:additionals"></span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>

            <tr>
              <td>
                <table cellPadding="8" style={{ width: "100%" }}>
                  <tbody>
                    <tr>
                      <td className="ccLeft width-255">
                        <span id="rcfield:5:labels">
                          {t("SDKUserForm.email")}
                          <span style={{ color: "red" }}> *</span>
                        </span>
                      </td>
                      <td className="ccRight width-525">
                        <span id="rcfield:5:values">
                          <input
                            id="rcfield:5:inputValue"
                            name="rcfield:5:inputValue"
                            type="text"
                            maxLength="255"
                            className="ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all width-450 email no-disable-field"
                            role="textbox"
                            aria-disabled="false"
                            aria-readonly="false"
                            placeholder="example@example.kz"
                            value={email}
                            onChange={handleEmailChange}
                            onFocus={handleInputFocus}
                            onBlur={handleInputBlur}
                          />
                        </span>
                        {!isValidEmail &&
                          !isInputFocusedEmail &&
                          email !== "" && (
                            <div className="error-message">
                              {t("SDKUserForm.errorEmail")}
                            </div>
                          )}
                      </td>
                      <td className="ccRight">
                        <span id="rcfield:5:additionals"></span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>

            <tr>
              <td>
                <table cellPadding="8" style={{ width: "100%" }}>
                  <tbody>
                    <tr>
                      <td className="ccLeft width-255">
                        <span id="rcfield:0:labels">
                          {t("SDKUserForm.userCount")}
                          <span style={{ color: "red" }}> *</span>
                        </span>
                      </td>
                      <td className="ccRight width-525">
                        <span id="rcfield:0:values">
                          <input
                            id="rcfield:0:inputValue"
                            name="rcfield:0:inputValue"
                            type="text"
                            maxLength="12"
                            className="ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all width-450 bin no-disable-field"
                            role="textbox"
                            aria-disabled="false"
                            aria-readonly="false"
                            placeholder="139549"
                            value={userCount}
                            onChange={handleUserCountChange}
                          />
                        </span>
                      </td>
                      <td className="ccRight">
                        <span id="rcfield:5:additionals"></span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          style={{
            backgroundColor: "#DEE5EB",
            padding: "5px",
            borderRadius: "1px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            margin: "5px 0",
          }}
        >
          <p
            style={{
              fontSize: "16px",
              fontFamily: "Montserrat, sans-serif",
              color: "#333",
              margin: 0,
            }}
          >
            {t("SDKUserForm.noteRegistration")}
          </p>
        </div>
        <span id="ncalayerCallBlock">
          <button
            id="submitDataBTest"
            name="submitDataBTest"
            className="ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only new-blue-btn w-100"
            onClick={handleSign}
            type="submit"
            role="button"
            aria-disabled="false"
          >
            <span className="ui-button-text ui-c">
              {t("SDKUserForm.signButton")}
            </span>
          </button>
          <button
            id="submitOrderBTest"
            name="submitOrderBTest"
            className=""
            onClick={() => {}}
            style={{ display: "none" }}
            type="submit"
            role="button"
            aria-disabled="false"
          >
            <span className="ui-button-text ui-c">ui-button</span>
          </button>
        </span>
      </form>
    </div>
  );
};

export default SDKUserEmailUpdateComponent;
