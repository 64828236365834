import React, { useState } from 'react';
import HeaderComponent from './component/HeaderComponent';
import FooterComponent from './component/FooterComponent';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import UlChekSDKUserFormComponent from './component/UlChekSDKUserFormComponent';
import UlSDKUserFormComponent from './component/UlSDKUserFormComponent';
import FlSDKUserFormComponent from './component/FlSDKUserFormComponent';
import UserSelectorComponent from './component/UserSelectorComponent';
import SDKUserSuccessComponent from './component/SDKUserSuccessComponent';
import SDKUserEmailUpdateComponent from './component/SDKUserEmailUpdateComponent';
import AuthenticationComponent from './component/AuthenticationComponent';
import ErrorComponent from './component/ErrorComponent';
// import CheckSDKUserFormComponent from './component/CheckSDKUserFormComponent';
import FlChekSDKUserFormComponent from './component/FlCheckSDKUserFormComponent';

function App() {
  const [language, setLanguage] = useState('ru');

  const changeLanguage = (newLanguage) => {
    setLanguage(newLanguage);
  };

  return (

    <BrowserRouter>
      <HeaderComponent language={language} changeLanguage={changeLanguage} />
      <div id="body">
        <Routes>
          <Route path="/" element={<UserSelectorComponent />} />
          <Route path="/ul-check" element={<UlChekSDKUserFormComponent />} />
          <Route path="/fl-check" element={<FlChekSDKUserFormComponent />} />
          <Route path="/ul-form" element={<UlSDKUserFormComponent />} />
          <Route path="/fl-form" element={<FlSDKUserFormComponent />} />
          <Route path="/success" element={<SDKUserSuccessComponent />} />
          <Route path="/auth" element={<AuthenticationComponent/>}/>
          <Route path="/update-email" element={<SDKUserEmailUpdateComponent/>}/>
          <Route path="/error" element={<ErrorComponent/>}/>
          
        </Routes>
      </div>
      <FooterComponent />
    </BrowserRouter>

  );
}

export default App;
