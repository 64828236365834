import React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const UserAgreement = ({ onClose }) => {
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const { t } = useTranslation();
  const handleCheckboxChange = (event) => {
    setIsCheckboxChecked(event.target.checked);
  };

  const handleAcceptButtonClick = () => {
    onClose();
  };

  const handleMainPageLinkClick = () => {
   
  };

  return (
    <div id="user_agreement">
      <div className="user-agreement">
        <div className="user-agreement__flex-wrapper">
          <div className="user-agreement__col1">
            <h3>«Қазақстан Республикасының Ұлттық куәландырушы орталығы» ақпараттық жүйесінде пайдаланушының келісімі</h3>

            <p>
              Мен, <span style={{ fontWeight: 'bold' }}>«Қазақстан Республикасының Ұлттық куәландырушы орталығы»</span> ақпараттық жүйесінің
              (бұдан әрі – Жүйе) пайдаланушысы, <span style={{ fontWeight: 'bold' }}>«Мемлекеттік көрсетілетін қызметтер туралы»</span> 2013
              жылғы 15 сәуірдегі № 88-V Қазақстан Республикасы заңы 5-бабының 2-тармағы 12)
              тармақшасына, <span style={{ fontWeight: 'bold' }}>«Жеке деректер және оларды қорғау туралы»</span> 2013 жылғы 21
              мамырдағы № 94-V Қазақстан Республикасының Заңы 10-бабының талаптарына сәйкес
              «Ұлттық ақпараттық технологиялар» АҚ
              (бұдан әрі - Оператор) «Қазақстан Республикасының Ұлттық куәландыру орталығының
              тіркеу куәлігін беру және кері қайтарып алу» мемлекеттік көрсетілетін
              қызметін алу және <span style={{ fontWeight: 'bold' }}>электрондық поштаға хабарламаларды алу мақсатында менің
                дербес деректерімді өңдеуге <u>өз келісімімді растаймын</u>.</span>
            </p>

            <p>Мен жинауға және өндеуге келісім берген дербес деректерге жататыны: </p>

            <ul>
              <li>тегі;</li>
              <li>аты;</li>
              <li>әкесінің аты;</li>
              <li>ЖСН;</li>
              <li>жұмыс орны: лауазымның, құрылымдық бөлімшенің, ұйымның атауы;</li>
              <li>байланыс ақпараты: электрондық пошта адресі, тұрғылықты облысы және қаласы.</li>
            </ul>

            <p>Менің жеке деректерімді «Жеке тұлғалар» мен «Заңды тұлғалар» мемлекеттік дерекқорларынан келесі мақсаттар үшін алуға келісім беремін:
            </p>

            <ul>
              <li>пайдаланушыны сәйкестендіруге;</li>
              <li>толтыру процесін автоматтандыруға;</li>
              <li>жеке ақпаратты дұрыс енгізуге.</li>
            </ul>

            <p>Мен жеке деректерімді өңдеуге келісім бермеген жағдайда, қызметтер маған толық көлемде
              көрсетілмейтіні туралы ескертілді.
            </p>

            <p>Сондай-ақ, қызметті алу үшін өтінімді тек өзімнің атымнан беру және қолданыстағы
              электрондық адресті ұсыну қажеттілігі туралы ескертті.
            </p>

            <p>Оператор менің жеке деректерімді автоматтандыру құралдарын пайдалана отырып өңдеуге құқылы.
            </p>

            <p>Мен оператордың адресіне өз келісімімді қайтарып алу туралы тиісті жазбаша құжатты жіберуге
              құқығым бар екенін білетінімді растаймын. Дербес деректер жалпыға қолжетімді болған кезде
              және "Дербес деректер және оларды қорғау туралы" 2013 жылғы 21 мамырдағы № 94-V Қазақстан
              Республикасы заңының 9-бабында көрсетілген негіздер болған кезде Оператор дербес деректерді
              өңдеуді менің келісімімсіз жалғастыруға құқылы.
            </p>

            <p>Өз кезегінде, "Электрондық құжат және электрондық цифрлық қолтаңба туралы" Қазақстан
              Республикасының 2003 жылғы 7 қаңтардағы № 370 заңының 16-бабына сәйкес Оператор
              Қазақстан Республикасы Үкіметінің 2018 жылғы 19 қыркүйектегі № 575 қаулысымен
              бекітілген "Ұлттық архив қорының құжаттарын және басқа да архив құжаттарын ведомстволық
              және жеке архивтердің қабылдау, сақтау, есепке алу және пайдалану қағидаларында" көзделген
              мерзімдерге сәйкес алынған Пайдаланушының  деректерінің архивтік сақталуын қамтамасыз етеді.
            </p>

            <p>Мен электрондық құжат айналымы жүйесіне қатысушылардың Қазақстан Республикасы
              электрондық құжат және электрондық цифрлық қолтаңба туралы заңнамалық
              актісінде көзделген міндеттердің орындамауы <span style={{ fontWeight: 'bold' }}>«Әкімшілік құқық бұзушылық
                туралы»</span> 2014 жылғы 5 шілдедегі № 235-V Қазақстан Республикасы Кодексінің
              640-бабына сәйкес айыппұл төлеуге әкеп соғатынын түсінемін.
            </p>

            <p>Осы Келісім пайдаланушы Келісім мәтінінің астында орналасқан «Пайдаланушылық
              келісімді оқыдым және шарттарымен келісемін» веб-интерфейсті басқару элементін
              басу нысанында Пайдаланушы олардың шарттарымен келісетінін білдіру арқылы күшіне енеді.
            </p>

          </div>
          <div className="user-agreement__col2">
            <h3>Пользовательское соглашение информационной системы «Национальный удостоверяющий центр Республики Казахстан»</h3>

            <p>
              Я, пользователь информационной системы
              <span style={{ fontWeight: 'bold' }}> «Национальный удостоверяющий центр Республики Казахстан»</span>
              (далее – Система) в соответствии с требованиями подпункта 12) пункта 2 статьи 5 Закона Республики
              Казахстан 15 апреля 2013 года № 88-V
              <span style={{ fontWeight: 'bold' }}> «О государственных услугах»</span>, статьей 10 Закона Республики
              Казахстан 21 мая 2013 года № 94-V
              <span style={{ fontWeight: 'bold' }}> «О персональных данных и их защите», <u>подтверждаю свое согласие</u></span>
              АО «Национальные информационные технологии» (далее - Оператор)
              <span style={{ fontWeight: 'bold' }}>на обработку моих персональных данных</span> в целях
              получения государственной услуги «Выдача и отзыв регистрационного свидетельства Национального
              удостоверяющего центра Республики Казахстан» и
              <span style={{ fontWeight: 'bold' }}>получения уведомлений на электронную почту.</span>
            </p>

            <p>К персональным данным, на сбор и обработку которых дается мое согласие, относятся:</p>

            <ul>
              <li>фамилия;</li>
              <li>имя;</li>
              <li>отчество;</li>
              <li>ИИН;</li>
              <li>место работы: наименование должности, структурного подразделения, организации;</li>
              <li>контактная информация: адрес электронной почты, область и город проживания.</li>
            </ul>

            <p>Я согласен, что мои персональные данные можно получить из государственных баз данных «Физические лица» и
              «Юридические лица» для следующих целей:
            </p>

            <ul>
              <li>для идентификации пользователя;</li>
              <li>автоматизации процесса заполнения;</li>
              <li>корректного ввода персональной информации.</li>
            </ul>

            <p>Я предупрежден, что в случае несогласия на обработку моих персональных данных, услуги не могут быть
              предоставлены мне в полном объеме.
            </p>

            <p>Я также предупрежден, что для получения услуги необходимо подавать заявку только от своего имени и
              предоставить действующий электронный адрес.
            </p>

            <p>Оператор вправе обрабатывать мои персональные данные с использованием средств автоматизации.
            </p>

            <p>Я подтверждаю, что мне известно о праве отзыва своего согласия посредством составления соответствующего
              письменного документа, который может быть направлен мной в адрес Оператора. В случае моего отзыва
              согласия на обработку персональных данных Оператор вправе продолжить обработку персональных данных
              без моего согласия, когда персональные данные сделаны общедоступными и при наличии оснований,
              указанных в статье 9 Закона Республики Казахстан от 21 мая 2013 года № 94-V
              «О персональных данных и их защите».
            </p>

            <p>В свою очередь, в соответствии со статьей 16 Закона Республики Казахстан от 7 января 2003 года № 370
              «Об электронном документе и электронной цифровой подписи», Оператор обеспечивает архивное хранение
              полученных пользовательских данных в соответствии со сроками, предусмотренными Правилами приема,
              хранения, учета и использования документов Национального архивного фонда и других архивных документов
              ведомственными и частными архивами, утвержденными постановлением Правительства Республики Казахстан
              от 19 сентября 2018 года № 575.
            </p>

            <p>Я понимаю, что невыполнение участниками системы электронного документооборота обязанностей,
              предусмотренных законодательным актом Республики Казахстан об электронном документе и электронной цифровой
              подписи, – влечет штрафные выплаты согласно статье 640 Кодекса Республики Казахстан от 5 июля 2014 года
              № 235-V ЗРК
              <span style={{ fontWeight: 'bold' }}> «Об административных правонарушениях».</span>
            </p>

            <p>Настоящее Соглашение вступает в силу путем выражения Пользователем согласия с их условиями в форме
              нажатия пользователем элемента управления веб-интерфейсом
              «Я прочитал (-а) и согласен (-на) с условиями пользовательского соглашения», помещенным под текстом
              Соглашения.
            </p>
          </div>
        </div>
      </div>
      <div className="user-agreement-button-area">
        <div>
          <input
            type="checkbox"
            id="myCheckbox"
            value="check"
            onChange={handleCheckboxChange}
          />
        
          <label htmlFor="myCheckbox">
          {t('userAgreement.checkBox')}</label>
        </div>
        <br />
        <input
          className="new-blue-btn"
          type="button"
          id="buttonAcceptAgreement"
          value={t('userAgreement.button')}
          onClick={handleAcceptButtonClick}
          disabled={!isCheckboxChecked}
        />
        <a
          className="user-agreement-main-page"
          href={`${process.env.PUBLIC_URL}/`}
          onClick={handleMainPageLinkClick}
        >
        {t('userAgreement.buttonMainPage')}
        </a>
      </div>
    </div>
  );
};

export default UserAgreement;
