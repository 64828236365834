import React, { useState, useEffect } from "react";
import NCALayerService from "../service/NCALayerService";
import SDKUserFormService from "../service/SDKUserFormService";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ErrorModal from "./ErrorModal";

const FlSDKUserFormComponent = () => {
  const location = useLocation();
  const { t } = useTranslation();
  // const { validatedFio } = location.state;

  const { validatedIIN } = location.state;

  const [informationSystemName, setInformationSystemName] = useState("");
  const [iin, setIin] = useState("");
  // const [fio, setFio] = useState("");

  const [personalPhoneNumber, setPersonalPhoneNumber] = useState("+7");
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);
  const [isInputFocusedPhoneNumber, setIsInputFocusedPhoneNumber] =
    useState(false);

  const [userCount, setUserCount] = useState("");

  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isInputFocusedEmail, setIsInputFocusedEmail] = useState(false);

  const [errorMessage, setErrorMessage] = useState(null);

  const xmlString = `<root>
      <iin>${validatedIIN || iin}</iin>
      <informationSystemName>${escapeXml(
        informationSystemName
      )}</informationSystemName>
      <personalPhoneNumber>${escapeXml(
        personalPhoneNumber
      )}</personalPhoneNumber>
  <email>${escapeXml(email)}</email>
  <userCount>${escapeXml(userCount)}</userCount>
  </root>`;

  const navigate = useNavigate();
  function escapeXml(unsafe) {
    return unsafe.replace(/[&<>"']/g, function (match) {
      switch (match) {
        case "&":
          return "&amp;";
        case "<":
          return "&lt;";
        case ">":
          return "&gt;";
        case '"':
          return "&quot;";
        case "'":
          return "&apos;";
        default:
          return match;
      }
    });
  }
  const handleSign = async (e) => {
    e.preventDefault();
    if (
      informationSystemName.trim() === "" ||
      !isValidPhoneNumber ||
      personalPhoneNumber === "+7" ||
      !isValidEmail ||
      email === "" ||
      userCount.trim() === "" ||
      userCount.trim() === "0"
    ) {
      setErrorMessage(t("error.correctInputError"));
      return;
    }
    try {
      const xmlResult = await NCALayerService.sign(
        xmlString,
        "1.3.6.1.5.5.7.3.4"
      );

      if (xmlResult == undefined) {
        navigate("/error", {
          replace: true,
          state: { error: t("error.ncalayer") },
        });
      } else {
        await SDKUserFormService.save(xmlResult);
        navigate("/success", { replace: true });
      }
    } catch (error) {
      if (
        error.response &&
        error.response.status === 404 &&
        error.response.data.includes("foreign.key")
      ) {
        setErrorMessage(t("error.foreignKeyError"));
        console.error("Используется чужая подпись");
      } else if (error.response && error.response.status === 404) {
        setErrorMessage(t("error.invalidSignatureErrorFl"));
        console.error("Ошибка: 404 invalidSignatureError");
      } else {
        // Обработка других случаев ошибок
        console.error("Другая ошибка:", error.message);
        navigate("/error", { replace: true });
      }
    }
  };

  //////////////email////////////////////
  const validateEmail = (inputEmail) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(inputEmail);
    setIsValidEmail(isValid);
    return isValid;
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    if (isInputFocusedEmail) {
      validateEmail(newEmail);
    }
  };
  const handleInputFocus = () => {
    setIsInputFocusedEmail(true);
  };

  const handleInputBlur = () => {
    setIsInputFocusedEmail(false);
    validateEmail(email);
  };
  // ///////////////////////phoneNumber///////////////////////////////////
  const validatePhoneNumber = (inputNumber) => {
    const phoneNumberRegex = /^(\+7|8)?\d{10}$/;
    const isValid = phoneNumberRegex.test(inputNumber);
    setIsValidPhoneNumber(isValid);
    return isValid;
  };

  const handlePersonalPhoneChange = (e) => {
    const inputValue = e.target.value;
    setPersonalPhoneNumber(inputValue);
    if (isInputFocusedPhoneNumber) {
      validatePhoneNumber(inputValue);
    }
  };

  const handleInputFocusPhoneNumber = () => {
    setIsInputFocusedPhoneNumber(true);
  };

  const handleInputBlurPhoneNumber = () => {
    setIsInputFocusedPhoneNumber(false);
    validatePhoneNumber(personalPhoneNumber);
  };

  /////////////////////userCount//////////////////////////////

  const handleUserCountChange = (e) => {
    let inputValue = e.target.value;

    let onlyNumbers = inputValue.replace(/[^\d]/g, "");

    onlyNumbers = onlyNumbers.replace(/^0+/, "");

    if (onlyNumbers === "") {
      setUserCount("");
    } else {
      setUserCount(onlyNumbers);
    }
  };
  return (
    <div className="content">
      <div className="titleCell">
        <span>{t("SDKUserForm.titleRegistration")}</span>
      </div>

      <form id="indexForm" className="indexForm">
        <div className="vktHeaderDiv">{t("SDKUserForm.formTitle")}</div>
        {errorMessage && <ErrorModal errorMessage={errorMessage} />}

        <table style={{ width: "100%" }}>
          <tbody>
            <tr>
              <td>
                <table cellPadding="8" style={{ width: "100%" }}>
                  <tbody>
                    <tr>
                      <td className="ccLeft width-255">
                        <span id="rcfield:0:labels">
                          {t("SDKUserForm.iin")}
                          <span style={{ color: "red" }}> *</span>
                        </span>
                      </td>
                      <td className="ccRight width-525">
                        <span id="rcfield:0:values">
                          <input
                            id="rcfield:0:inputValue"
                            name="rcfield:0:inputValue"
                            type="text"
                            maxLength="12"
                            className="ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all width-450 bin"
                            role="textbox"
                            aria-disabled="false"
                            aria-readonly="false"
                            placeholder={t("SDKUserForm.mandatoryPlaceholder")}
                            value={validatedIIN || iin}
                            onChange={(e) =>
                              setIin(e.target.value || validatedIIN)
                            }
                            disabled={!!validatedIIN}
                          />
                        </span>
                      </td>
                      <td className="ccRight">
                        <span id="rcfield:5:additionals"></span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            {/* <tr>
              <td>
                <table cellPadding="8" style={{ width: "100%" }}>
                  <tbody>
                    <tr>
                      <td className="ccLeft width-255">
                        <span id="rcfield:0:labels">
                          {t("SDKUserForm.fio")}
                          <span style={{ color: "red" }}> *</span>
                        </span>
                      </td>
                      <td className="ccRight width-525">
                        <span id="rcfield:0:values">
                          <input
                            id="rcfield:0:inputValue"
                            name="rcfield:0:inputValue"
                            type="text"
                            maxLength="255"
                            className="ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all width-450 bin"
                            role="textbox"
                            aria-disabled="false"
                            aria-readonly="false"
                            placeholder={t("SDKUserForm.mandatoryPlaceholder")}
                            value={validatedFio || fio}
                            onChange={(e) => setFio(e.target.value)}
                            disabled={!!validatedFio}
                          />
                        </span>
                      </td>
                      <td className="ccRight">
                        <span id="rcfield:5:additionals"></span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr> */}

            <tr>
              <td>
                <table cellPadding="8" style={{ width: "100%" }}>
                  <tbody>
                    <tr>
                      <td className="ccLeft width-255">
                        <span id="rcfield:0:labels">
                          {t("SDKUserForm.informationSystem")}
                          <span style={{ color: "red" }}> *</span>
                        </span>
                      </td>
                      <td className="ccRight width-525">
                        <span id="rcfield:0:values">
                          <input
                            id="rcfield:0:inputValue"
                            name="rcfield:0:inputValue"
                            type="text"
                            maxLength="255"
                            className="ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all width-450 bin no-disable-field"
                            role="textbox"
                            aria-disabled="false"
                            aria-readonly="false"
                            placeholder={t("SDKUserForm.mandatoryPlaceholder")}
                            value={informationSystemName}
                            onChange={(e) =>
                              setInformationSystemName(e.target.value)
                            }
                          />
                        </span>
                      </td>
                      <td className="ccRight">
                        <span id="rcfield:5:additionals"></span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td>
                <table cellPadding="8" style={{ width: "100%" }}>
                  <tbody>
                    <tr>
                      <td className="ccLeft width-255">
                        <span id="rcfield:0:labels">
                          {t("SDKUserForm.phoneNumber")}
                          <span style={{ color: "red" }}> *</span>
                        </span>
                      </td>
                      <td className="ccRight width-525">
                        <span id="rcfield:0:values">
                          <input
                            id="rcfield:0:inputValue"
                            name="rcfield:0:inputValue"
                            type="text"
                            maxLength="12"
                            pattern="\d*"
                            className="ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all width-450 bin no-disable-field"
                            role="textbox"
                            aria-disabled="false"
                            aria-readonly="false"
                            placeholder="+7(_ _ _) _ _ _ - _ _ - _ _"
                            value={personalPhoneNumber}
                            onChange={handlePersonalPhoneChange}
                            onFocus={handleInputFocusPhoneNumber}
                            onBlur={handleInputBlurPhoneNumber}
                          />
                        </span>
                        {!isValidPhoneNumber &&
                          !isInputFocusedPhoneNumber &&
                          (personalPhoneNumber.trim() !== "" ||
                            personalPhoneNumber === "+7") && (
                            <div className="error-message">
                              {t("SDKUserForm.errorPhoneNumber")}
                            </div>
                          )}
                      </td>
                      <td className="ccRight">
                        <span id="rcfield:5:additionals"></span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>

            <tr>
              <td>
                <table cellPadding="8" style={{ width: "100%" }}>
                  <tbody>
                    <tr>
                      <td className="ccLeft width-255">
                        <span id="rcfield:5:labels">
                          {t("SDKUserForm.email")}
                          <span style={{ color: "red" }}> *</span>
                        </span>
                      </td>
                      <td className="ccRight width-525">
                        <span id="rcfield:5:values">
                          <input
                            id="rcfield:5:inputValue"
                            name="rcfield:5:inputValue"
                            type="text"
                            maxLength="255"
                            className="ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all width-450 email no-disable-field"
                            role="textbox"
                            aria-disabled="false"
                            aria-readonly="false"
                            placeholder="example@example.kz"
                            value={email}
                            onChange={handleEmailChange}
                            onFocus={handleInputFocus}
                            onBlur={handleInputBlur}
                          />
                        </span>
                        {!isValidEmail &&
                          !isInputFocusedEmail &&
                          email.trim() !== "" && (
                            <div className="error-message">
                              {t("SDKUserForm.errorEmail")}
                            </div>
                          )}
                      </td>
                      <td className="ccRight">
                        <span id="rcfield:5:additionals"></span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>

            <tr>
              <td>
                <table cellPadding="8" style={{ width: "100%" }}>
                  <tbody>
                    <tr>
                      <td className="ccLeft width-255">
                        <span id="rcfield:0:labels">
                          {t("SDKUserForm.userCount")}
                          <span style={{ color: "red" }}> *</span>
                        </span>
                      </td>
                      <td className="ccRight width-525">
                        <span id="rcfield:0:values">
                          <input
                            id="rcfield:0:inputValue"
                            name="rcfield:0:inputValue"
                            type="text"
                            maxLength="12"
                            className="ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all width-450 bin no-disable-field"
                            role="textbox"
                            aria-disabled="false"
                            aria-readonly="false"
                            placeholder="139549"
                            value={userCount}
                            onChange={handleUserCountChange}
                          />
                        </span>
                      </td>
                      <td className="ccRight">
                        <span id="rcfield:5:additionals"></span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
        <br></br>
        <span id="ncalayerCallBlock">
          <button
            id="submitDataBTest"
            name="submitDataBTest"
            className="ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only new-blue-btn w-100"
            onClick={handleSign}
            type="submit"
            role="button"
            aria-disabled="false"
          >
            <span className="ui-button-text ui-c">
              {t("SDKUserForm.signButton")}
            </span>
          </button>
          <button
            id="submitOrderBTest"
            name="submitOrderBTest"
            className=""
            onClick={() => {}}
            style={{ display: "none" }}
            type="submit"
            role="button"
            aria-disabled="false"
          >
            <span className="ui-button-text ui-c">ui-button</span>
          </button>
        </span>
      </form>
    </div>
  );
};

export default FlSDKUserFormComponent;
