import React, { useState, useEffect } from 'react';
import config, { API_PKI } from '../config';
import { useTranslation } from 'react-i18next';
const SDKUserSuccessComponent = () => {


const {t} = useTranslation();

    return (
        <div className="content">

            <div className="titleCell">
                <span>{t('SDKUserForm.successTitle')}</span>
            </div>
            <form id="indexForm" className="indexForm">
                <p>{t('SDKUserForm.successText1')} <a href={API_PKI + '/get-sdk/'} target="_blank">{t('SDKUserForm.successText2')}</a> {t('SDKUserForm.successText3')}</p>
                
            </form>
        </div>
    );
};


export default SDKUserSuccessComponent;