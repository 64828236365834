import React from 'react';
import { useTranslation } from 'react-i18next';



const FooterComponent = () => {
  const {t} = useTranslation();
  return (
    <div id="footer-main">
      <div id="footer2">
        <div className="copyright">

          <span> {t('footer.companyName')} </span>
          <br />
          <br />
          <span>
          {t('footer.address')}
          </span>
          <br />
          <span>{t('footer.number')}</span>
          <br />
          <span>{t('footer.support')} <a href="mailto:support@goscorp.kz">support@goscorp.kz</a></span>

        </div>
      </div>
    </div>
  );
};

export default FooterComponent;
