import React from 'react';

const ErrorModal = ({ errorMessage }) => {
    return (
        <div style={styles.container}>
            <span style={styles.icon}>❌</span>
            <span style={styles.message}>{errorMessage}</span>
        </div>
    );
}

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#f8d7da',
        color: '#721c24',
        padding: '5px',
        border: '1px solid #f5c6cb',
    },
    icon: {
        marginRight: '10px',
        fontSize: '20px',
    },
    message: {
        fontSize: '16px',
    }
};

export default ErrorModal;
