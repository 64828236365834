import axios from "axios";
import config, { API_JAVA } from '../config';
const BASE_URL = API_JAVA;

class CheckSDKUserFormService{
    
    checkUl(captcha, captchaKey, bin, iin, type) {
        return axios.post(`${BASE_URL}/check`, {captcha, captchaKey, bin, iin, type});
    }
    checkFl(captcha, captchaKey, iin, type) {
        return axios.post(`${BASE_URL}/check`, {captcha, captchaKey, iin, type});
    }

}

export default new CheckSDKUserFormService();
