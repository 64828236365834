import axios from "axios";
import config, { API_JAVA } from '../config';
const BASE_URL = API_JAVA;


class SDKUserFormService {

    save(result) {
        return axios.post(`${BASE_URL}/save`, { result });
    }

}

export default new SDKUserFormService();
