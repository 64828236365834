// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  ru: {
    translation: {
      header: {
        title: 'Национальный удостоверяющий центр',
        subtitle: 'Республики Казахстан',
        link1: 'Получить ссылку',
        link2: 'Обновить данные'
      },
      footer: {
        companyName: '© 2024 АО «Национальные информационные технологии» ',
        address: 'Адрес: Республика Казахстан, 010000, г. Астана, левобережье, пр. Мәңгілік Ел 55/15, Бизнес - центр «EXPO»',
        number: 'Телефон: 8-800-080-7777; 1414 (бесплатная телефонная линия на территории РК)',
        support: 'Техническая поддержка:'
      },
      userAgreement: {
        checkBox: 'Я прочитал(-а) и согласен(-на) с условиями пользовательского соглашения.',
        buttonMainPage: 'Вернуться на главную страницу',
        button: 'Подтвердить'
      },
      authentication: {
        title: 'Аутентификация',
        authWarningTitle: 'Уведомление об ответственности',
        authWarning: 'Я, пользователь информационной системы «Национальный удостоверяющий центр Республики Казахстан» (далее – Система), продолжая работу на интернет-ресурсе Системы подтверждаю свое согласие, что несу ответственность за все осуществленные действия в соответствии с Законом Республики Казахстан от 7 января 2003 года N 370 «Об электронном документе и электронной цифровой подписи».',
        checkBox: 'Подтверждаю',
        button: 'Выбрать ключ ЭЦП'
      },
      userSelector: {
        userSelectorTitle: 'РЕГИСТРАЦИЯ ЗАЯВКИ ДЛЯ ПОЛУЧЕНИЯ SDK',
        ulButton: 'Юридическое лицо',
        flButton: 'Физическое лицо'
      },
      SDKUserForm: {
        titleRegistration: 'Регистрация заявки для получения SDK',
        titleUpdate: 'Обновление данных',
        formTitle: 'Данные для регистрации заявки:',
        captchaText: 'Введите код с картинки:',
        checkIin: 'Проверить',
        check: 'Проверить',
        organizationName: 'Наименование организации:',
        informationSystemNameOwner: 'Владелец информационной системы: ',
        informationSystem: 'Информационная система',
        bin: 'БИН:',
        iin: 'ИИН:',
        fio: 'ФИО:',
        phoneNumber:'Номер телефона:',
        errorPhoneNumber:'Некорректный номер. Пожалуйста, введите правильный номер телефона.',
        email:'Электронная почта:',
        errorEmail:'Некорректный email. Пожалуйста, введите правильный адрес.',
        userCount:'Планируемое количество пользователей ИС:',
        noteUpdate:'Примечание: при необходимости актуализируйте информацию о информационной системе',
        noteRegistration: ' Примечание: необходимо подписать посредством ЭЦП «Первого руководителя» или «Сотрудника с правом подписи» Вашей организации',
        signButton:'Подписать',
        successTitle:'ВАША ЗАЯВКА УСПЕШНО РЕГИСТРИРОВАНА',
        successText1:'Ваша заявка на получение SDK успешно зарегистрирована. Переходите по',
        successText2: 'ссылке',
        successText3: ' и введите вашу электронную почту для получения одноразовой ссылки.',
        mandatoryPlaceholder: 'Заполнение обязательно',
        selectIS: 'Выберите информационную систему'

      },
      error: {
        mainError: 'Ошибка',
        defaultError: 'Что-то пошло не так. Попробуйте позже.',
        informationSystemNotFoundError: 'Информационная система не найдена в базе',
        invalidSignatureErrorFl: 'Недопустимый ЭЦП - используйте ключ физического лица',
        invalidSignatureError: 'Недопустимый ЭЦП',
        serverError: 'Данные от сервера отсутствуют или некорректны',
        correctInputError: 'Корректно заполните все поля',
        captchaError: 'Ошибка при получении изображения CAPTCHA',
        captchaInputError: 'Неправильно указан код с картинки',
        userNotFoundError: 'Пользователь не найден',
        // underAgeError: 'Данное физическое лицо не достигло 16 лет',
        underAgeError: 'Выдача SDK по указанному  ИИН – не возможна, на основании Статьи 14. Главы 4 Закона Республики Казахстан «Об электронном документе и электронной цифровой подписи», регистрационное свидетельство выдается лицу, достигшему шестнадцатилетнего возраста, в порядке установленном уполномоченным органом в сфере информатизации.',
        decasedError: 'Согласно информации из Государственной базы данных физических лиц (далее - ГБД ФЛ), указанный ИИН имеет статус «Умерший». Для получения дополнительной информации Вам необходимо обратиться в ГБД ФЛ.',
        foreignKeyError: 'Используется чужая подпись',
          ncalayer: "Убедитесь, что NCALayer запущен"

        
      },
      
      
      // Добавьте другие строки перевода, если необходимо
    },
  },
  kz: {
    translation: {
      header: {
        title: 'Қазақстан Республикасының',
        subtitle: 'Ұлттық куәландырушы орталығы',
        link1: 'Сілтемені алу',
        link2: 'Деректерді жаңарту',
      },
      footer: {
        companyName: '© 2024  «Ұлттық ақпараттық технологиялар» АҚ',
        address: 'Мекен-жайы: Қазақстан Республикасы, 010000, Астана қ, Сол жағалау, Мәңгілік Ел д-лы, 55/15, "EXPO" бизнес орталығы',
        number: 'Телефон: 8-800-080-7777; 1414 (ҚР аумағында тегін қоңырау шалу желісі)',
        support: 'Техникалық көмек:'
      },
      userAgreement: {
        checkBox: 'Пайдаланушылық келісімді оқыдым және шарттарымен келісемін.',
        buttonMainPage: 'Бастапқы бетке қайту',
        button: 'Енгізу'
      },
      authentication: {
        title: 'Түпнұсқаландыру',
        authWarningTitle: 'Жауапкершілік туралы хабарлама',
        authWarning: 'Мен, Қазақстан Республикасының "Ұлттық куәландырушы орталығы" ақпараттық жүйесінің (бұдан әрі – Жүйе) пайдаланушысы, Жүйенің интернет-ресурсында жұмысты жалғастыра отырып, "Электрондық құжат және электрондық цифрлық қолтаңба туралы" Қазақстан Республикасының 2003 жылғы 7 қаңтардағы N 370 Заңына сәйкес жүзеге асырылған барлық іс-әрекеттер үшін жауапкершілік артатынымды растаймын.',
        checkBox: 'Растаймын',
        button: 'ЭЦҚ кілтін таңдау'
      },

      userSelector: {
        userSelectorTitle: 'SDK АЛУ ҮШІН ӨТІНІМДІ ТІРКЕУ',
        ulButton: 'Заңды тұлға',
        flButton: 'Жеке тұлға'
      },
      SDKUserForm: {
        titleRegistration: 'SDK алу үшін өтінімді тіркеу',
        titleUpdate: 'Деректерді жаңарту',
        formTitle:'Өтінімді тіркеуге арналған деректер:',
        captchaText:'Суреттегі кодты енгізіңіз:',
        checkIin:'Тексеру',
        check:'Тексеру',
        organizationName:'Ұйымның атауы:',
        informationSystemNameOwner: 'Aқпараттық жүйенің иесі:',
        informationSystem: 'Ақпараттық жүйе',
        bin: 'БСН:',
        iin:'ЖСН:',
        fio:'Аты-жөні:',
        phoneNumber:'Телефон нөмірі:',
        errorPhoneNumber: 'Қате нөмір. Дұрыс телефон нөмірін енгізіңіз.',
        email:'Электрондық пошта:',
        errorEmail: 'Электрондық пошта қате енгізілген. Дұрыс поштаны енгізіңіз.',
        userCount:'АЖ пайдаланушыларының жоспарланған саны:',
        ескерту: 'Ескерту: қажет болса, ақпараттық жүйе туралы ақпаратты жаңартыңыз',
        noteRegistration: 'Ескерту: Сіздің ұйымыңыздың "Бірінші басшы" немесе "Қол қою құқығы бар қызметкер" ЭЦҚ-сы арқылы қол қою қажет.',
        signButton: 'Қол қою',
        successTitle: 'Сіздің өтініміңіз сәтті тіркелді',
        successText1: 'Сіздің SDK өтініміңіз сәтті тіркелді. Осы ',
        successText2: 'сілтемеге',
        successText3: ' өтіп, бір реттік сілтеме алу үшін электрондық поштаңызды енгізіңіз.',
        mandatoryPlaceholder: 'Толтыру міндетті',
        selectIS: 'Ақпараттық жүйені таңдаңыз'
      },
        error: {
          mainError: 'Қате',
          defaultError: 'Белгісіз қате.Кейінірек көріңіз',
          informationSystemNotFoundError: 'Ақпараттық жүйе дерекқордан табылмады',
          invalidSignatureErrorFl: 'Қате ЭЦҚ - жеке тұлғаның кілтін пайдаланыңыз',
          invalidSignatureError: 'Қате ЭЦҚ',
          serverError: 'Серверден деректер жоқ немесе дұрыс емес',
          correctInputError: 'Барлық өрістерді дұрыс толтырыңыз',
          captchaError: 'CAPTCHA кескінін алу кезінде қате анықталды', 
          captchaInputError: 'Суреттегі код қате терілді',
          userNotFoundError: 'Пайдаланушы табылмады',
          underAgeError: 'Көрсетілген ЖСН бойынша SDK беру мүмкін емес, «Электрондық құжат және электрондық цифрлық қолтаңба туралы» Қазақстан Республикасының заңы 4-бөлімінің 14-бабы негізінде тіркеу куәлігі ақпараттандыру саласындағы уәкілетті орган белгілеген тәртіппен он алты жасқа толған тұлғаға беріледі.',
          decasedError: 'Жеке тұлғалардың мемлекеттік дерекқорынан (бұдан әрі-ЖТ МДҚ) алынған ақпаратқа сәйкес көрсетілген ЖСН мәртебесі «Қайтыс болған».  Қосымша ақпарат алу үшін ЖТ МДҚ-ға  хабарласуыңыз қажет.',
          foreignKeyError: 'Өзге тұлғаның қолы қолданылды',
          ncalayer: "NCALayer жұмыс істеп тұрғанына көз жеткізіңіз"
        },
      
    },
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'ru', // default language
    interpolation: {
      escapeValue: false, // not needed for React as it escapes by default
    },
  });

export default i18n;
