import React, { useState } from 'react';

import CheckSDKUserFormService from '../service/CheckSDKUserFormService';
import config, { API_JAVA } from '../config';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import axios from "axios";
import UserAgreement from './UserAgreement';
import { useTranslation } from 'react-i18next';
import ErrorModal from './ErrorModal';

const FlChekSDKUserFormComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [captcha, setCaptcha] = useState('');
  const [captchaKey, setCaptchaKey] = useState('');
  const [iin, setIin] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [captchaImage, setCaptchaImage] = useState('');
  const [isPopupVisible, setPopupVisibility] = useState(true);
  const type = 'fl';

  useEffect(() => {
    axios.get(`${API_JAVA}/generateCaptcha`)
      .then(response => {
       
        const captchaKey = response.data.captchaKey;
        const captchaImage = response.data.image;
        setCaptchaKey(captchaKey);
        setCaptchaImage(captchaImage);
        setCaptcha('');

      })
      .catch(error => {
        console.error('Ошибка при получении изображения CAPTCHA:', error);
        navigate('/error', {
          state: {replace: true,
              error: t('error.captchaError'),
          },
      });
      });
  }, []);

  const handleCheckIin = async (e) => {
    e.preventDefault();

    const iinRegex = /^\d{12}$/;
    if (!iinRegex.test(iin)) {
      setErrorMessage(t('error.correctInputError'));
      return;
    }

    try {
      const response = await CheckSDKUserFormService.checkFl(captcha, captchaKey, iin, type);
      const validatedIIN = response.data.iin;
      // const validatedFio = response.data.fio;
      setErrorMessage(null);
      navigate("/fl-form", { replace: true, state: { validatedIIN } });
    } catch (error) {
      if (error.response && error.response.data.includes('Invalid captcha') && error.response.status === 400) {
        setErrorMessage(t('error.captchaInputError'));
        console.error(error.message);
      } else if (error.response.data.includes("message.error.gbd.person.underage")) {
        setErrorMessage(t('error.underAgeError'));
        console.error(error.message);
      }
      else if (error.response.data.includes("message.error.gbd.person.deceased")) {
        setErrorMessage(t('error.decasedError'));
        console.error(error.message);
      }
        else if (error.response.status === 404){
        setErrorMessage(t('error.userNotFoundError'));
        console.error(error.message);
      }
        else {
        console.error('Другая ошибка:', error.message);
        navigate("/error", {replace: true});
      }
      axios.get(`${API_JAVA}/generateCaptcha`)
        .then(response => {
       
          const captchaKey = response.data.captchaKey;
          const captchaImage = response.data.image;
          setCaptchaKey(captchaKey);
          setCaptchaImage(captchaImage);
          setCaptcha('');
        })
        .catch(error => {
          console.error('Ошибка при получении изображения CAPTCHA:', error);
          navigate('/error', {
            state: {replace: true,
                error: t('error.captchaError'),
            },
        });
        });
    }
  };



//   try {
//     const response = await CheckSDKUserFormService.checkFl(
//       captcha,
//       captchaKey,
//       iin,
//       type
//     );
  
//     console.log(response);
//     let validatedIIN = "";
//     let validatedFio = "";
//     let sdkUsers = [];
  
//     if (Array.isArray(response.data)) {
//       // Если data это массив SDKUser
//       sdkUsers = response.data;
//       validatedIIN = iin;
//       validatedFio = ""; // Если у SDKUser нет поля fio, оставляем пустым
//     } else {
//       // Если data это объект CheckedSDKUser
//       validatedIIN = iin;
//       validatedFio = response.data.fio;
//     }
//     setErrorMessage(null);
//     navigate("/fl-form", {
//       replace: true,
//       state: { validatedIIN, validatedFio, sdkUsers },
//     });
//   } catch (error) {
//     if (
//       error.response &&
//       error.response.data.includes("Invalid captcha") &&
//       error.response.status === 400
//     ) {
//       setErrorMessage(t("error.captchaInputError"));
//       console.error(error.message);
//     } else if (
//       error.response.data.includes("message.error.gbd.person.underage")
//     ) {
//       setErrorMessage(t("error.underAgeError"));
//       console.error(error.message);
//     } else if (
//       error.response.data.includes("message.error.gbd.person.deceased")
//     ) {
//       setErrorMessage(t("error.decasedError"));
//       console.error(error.message);
//     } else if (error.response.status === 404) {
//       setErrorMessage(t("error.userNotFoundError"));
//       console.error(error.message);
//     } else {
//       console.error("Другая ошибка:", error.message);
//       navigate("/error", { replace: true });
//     }
//     axios
//       .get(`${API_JAVA}/generateCaptcha`)
//       .then((response) => {
//         console.log(response.data);
//         const captchaKey = response.data.captchaKey;
//         const captchaImage = response.data.image;
//         setCaptchaKey(captchaKey);
//         setCaptchaImage(captchaImage);
//         setCaptcha("");
//       })
//       .catch((error) => {
//         console.error("Ошибка при получении изображения CAPTCHA:", error);
//         navigate("/error", {
//           state: { replace: true, error: t("error.captchaError") },
//         });
//       });
//   }
// };
  const handleClosePopup = () => {
    setPopupVisibility(false);
  };
  return (

    <div className="content">
      {isPopupVisible && (
        <div>
          <div className="overlay"  >

          </div>
          <div className="popup-content">
            <UserAgreement onClose={handleClosePopup} />

          </div>
        </div>
      )}
      <div className="titleCell">
        <span>{t('SDKUserForm.titleRegistration')}</span>
      </div>

      <form id="indexForm" className="indexForm" onSubmit={handleCheckIin}>
      {errorMessage && <ErrorModal errorMessage={errorMessage}/>}
        <div className="vktHeaderDiv">
       {t('SDKUserForm.formTitle')}
        </div>
        {/* {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>} */}
        
        <table cellPadding="8" style={{ width: '100%' }}>
          <tbody>
            <tr>
              <td className="ccLeft width-255" >
                <span style={{ verticalAlign: 'middle' }}>
                  {t('SDKUserForm.captchaText')}<span style={{ color: 'red' }}> *</span>
                </span>
              </td>
              <td className="ccRight width-525" >
                <input
                  id="captcha"
                  name="captcha"
                  type="text"
                  maxLength="5"

                  className="ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all width-450 no-disable-field"
                  role="textbox"
                  aria-disabled="false"
                  aria-readonly="false"
                  value={captcha}
                  onChange={(e) => setCaptcha(e.target.value)}
                />
              </td>
              <td className="ccRight" >
                <span id="captchaImage" >
                  <img src={`data:image/png;base64,${captchaImage}`} alt="Captcha" />

                </span>

              </td>
            </tr>
          </tbody>
        </table>
        <table id="rcfield" cellPadding={0} style={{ width: "100%" }}>
          <tbody>
            <tr>
              <td>
                <table cellPadding="8" style={{ width: '100%' }}>
                  <tbody>
                    <tr>
                      <td className="ccLeft width-255" style={{ width: '236.91px' }}>
                        <span id="rcfield:0:labels">{t('SDKUserForm.iin')}<span style={{ color: 'red' }}> *</span></span>
                      </td>
                      <td className="ccRight width-525">
                        <span id="rcfield:0:values">
                          <input
                            id="rcfield:0:inputValue"
                            name="rcfield:0:inputValue"
                            type="text"
                            maxLength="12"
                            className="ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all width-450 bin no-disable-field"
                            role="textbox"
                            aria-disabled="false"
                            aria-readonly="false"
                            placeholder="348951276584"
                            value={iin}
                            onChange={(e) => setIin(e.target.value)}
                          />
                        </span>
                      </td>
                      <td className="ccRight">
                        <span id="rcfield:0:additionals" >
                          <button
                            id="rcfield:0:checkCompanyButton"
                            name="rcfield:0:checkCompanyButton"
                            className="ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only new-blue-btn"
                            type="submit"
                            role="button"
                            aria-disabled="false"
                          >
                            <span className="ui-button-text ui-c">{t('SDKUserForm.checkIin')}</span>
                          </button>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            {/* Дополнительные tr-элементы здесь, если необходимо */}
          </tbody>
        </table>

      </form>
    </div>


  );
};

export default FlChekSDKUserFormComponent;
