import axios from "axios";
import  { API_JAVA } from '../config';
const BASE_URL = API_JAVA;

class AuthenticationService {

    // getKeySettings() {
    //     return axios.get(`${BASE_URL}/pre-auth`);
    // }

    getUser(result) {
        return axios.post(`${BASE_URL}/auth`, { result });
    }

}

export default new AuthenticationService();
