
import $ from 'jquery'; // Добавляем импорт jQuery
import './jquery.blockUI.js'; // Возможно, вам нужно добавить путь к файлу jquery.blockUI.js


const SOCKET_URL = 'wss://127.0.0.1:13579/';

class NCALayerService {
  static webSocket = null;

  static connect() {
    if (NCALayerService.webSocket && NCALayerService.webSocket.readyState < 2) {
      console.log("reusing the socket connection [state = " + NCALayerService.webSocket.readyState + "]: " + NCALayerService.webSocket.url);
      return Promise.resolve(NCALayerService.webSocket);
    }

    return new Promise(function (resolve, reject) {
      NCALayerService.webSocket = new WebSocket(SOCKET_URL);

      NCALayerService.webSocket.onopen = function () {
        console.log("socket connection is opened [state = " + NCALayerService.webSocket.readyState + "]: " + NCALayerService.webSocket.url);
        resolve(NCALayerService.webSocket);
      };

      NCALayerService.webSocket.onerror = function (err) {
        NCALayerService.unblockScreen();
        console.error("socket connection error : ", err);
        reject(err);
       
      };

      NCALayerService.webSocket.onclose = function (event) {
        if (event.wasClean) {
          console.error("socket connection is closed ");
        } else {
          console.log('Connection error');
        }
        console.log('Code: ' + event.code + ' Reason: ' + event.reason);
      };
    });
  }


  static async sign(xmlData, keyOid) {

    return await NCALayerService.request(xmlData, keyOid);
  }

  static async request(xmlData, keyOid) {
    NCALayerService.blockScreen();

    var selectedStorages = [];
    var storageCheckboxes = ['AKKaztokenStore', 'AKKZIDCardStore', 'AKEToken72KStore', 'AKEToken5110Store', 'AKJaCartaStore', 'PKCS12', 'JKS', 'AKAKEYStore'];
    // var storageCheckboxes = null;
    for (var i = 0; i < storageCheckboxes.length; i++) {
      selectedStorages.push(storageCheckboxes[i])
    }

    var signatureType = 'xml';

    var dataToSign = xmlData;

    var decode = false

    var encapsulate = false

    var digested = false
  //  var extKeyUsageOidStringAuth = '1.3.6.1.5.5.7.3.2';
    // var extKeyUsageOidString = '1.3.6.1.5.5.7.3.4';
    var extKeyUsageOidString = keyOid;
    var extKeyUsageOids = extKeyUsageOidString ? extKeyUsageOidString.split(",") : [];

    var caCertsString = '';
    var caCerts = [];
    // if (document.getElementById("buildChain").checked) {
    //     caCerts = caCertsString ? caCertsString.split(",") : null;
    // } else {
    //     caCerts = null;
    //     // caCerts = []; // CertPathBuilder will not be invoked. Any certificate will be accepted.
    // }

    // var localeRadio = $('input[name=locale]:checked').val();

    var tsaProfile = null;
    // if (document.getElementById("tsaProfile").checked) {
    //     tsaProfile = {};
    // }

    // var iin = '991022350427';
    // var bin = '991022350427';
    // var serialNumber = $('input[name=serialNumber]').val();

    var signInfo = {
      "module": "kz.gov.pki.knca.basics",
      "method": "sign",
      "args": {
        "allowedStorages": selectedStorages,
        "format": signatureType,
        "data": dataToSign,
        "signingParams": { decode, encapsulate, digested, tsaProfile },
        "signerParams": {
          "extKeyUsageOids": extKeyUsageOids,
          // "iin": iin,
          // "bin": bin,
          // "serialNumber": serialNumber,
          "chain": caCerts
        },
        "locale": "ru"
      }
    }

    if (selectedStorages.length == 0) {
      delete signInfo.args.allowedStorages;
    }


    return NCALayerService.connect().then((webSocket) => {
      webSocket.send(JSON.stringify(signInfo));

      return new Promise((resolve, reject) => {
        webSocket.onmessage = ({ data }) => {
          const response = JSON.parse(data);
          if (response != null) {
            var responseStatus = response['status'];
            if (responseStatus === true) {
              var responseBody = response['body'];
              if (responseBody != null) {
                NCALayerService.unblockScreen();
                if (responseBody.hasOwnProperty('result')) {
                  var result = responseBody.result;
                  resolve(result) ;
                }
              }
            } else if (responseStatus === false) {
              NCALayerService.unblockScreen();
              var responseCode = response['code'];
              alert(responseCode);
              reject(responseCode);
            }
          }
          //resolve(response);
        };
      });
    }).catch(function (err) {
      NCALayerService.unblockScreen();
      console.log(err);
    });
  }

  

  static blockScreen() {
    // ... код блокировки экрана
    $.blockUI({
      message: '<br/>NCALayer-дің жауабын күте тұрыңыз<br/>Подождите, выполняется операция в NCALayer...',
      css: {
        border: 'none',
        padding: '15px',
        backgroundColor: '#000',
        '-webkit-border-radius': '10px',
        '-moz-border-radius': '10px',
        opacity: .5,
        color: '#fff'
      }
      // <img src="loading.gif" />
    });
  }

  static unblockScreen() {
    // ... код разблокировки экрана
    $.unblockUI();
  }
}

export default NCALayerService;