import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import config, { API_PKI } from '../config';

const HeaderComponent = ({ changeLanguage }) => {
  const { t, i18n } = useTranslation();

  const switchLanguage = (lng) => {
    i18n.changeLanguage(lng);
    changeLanguage(lng);
  };
  

  return (
    <div id="header">
      <div id="header-content">
        <a href="/">
            <div>
              <h1 className="toph">{t('header.title')}</h1>
              <h2 className="both">{t('header.subtitle')}</h2>
            </div>
        
        </a>

        <div className="s_l">
          <div className="lang">
            <div id="jflanguageselection">
              <form id="languageForm" className="form-hidden">
                <ul className="jflanguageselection">
            
                      <li id={i18n.language === 'kz' ? "active_language" : ""}>
                        <a
                          id="linkKaz"
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            switchLanguage('kz');
                          }}
                        >
                          <span lang="kz">KZ</span>
                        </a>
                      </li>
                      <li id={i18n.language === 'ru' ? "active_language" : ""}>
                        <a
                          id="linkRus"
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            switchLanguage('ru');
                          }}
                        >
                          <span lang="ru">RU</span>
                        </a>
                      </li>
                
                </ul>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div id="menu">
        <div id="menu2">
          <ul className="menu-main">
            <li style={{ borderLeftWidth: '0px' }}>
        
              {/* <a href="https://pki.gov.kz/get-sdk/" style={{ color: 'white', textDecoration: 'none' }}> */}
             <a href={API_PKI + '/get-sdk/'} style={{ color: 'white', textDecoration: 'none' }}>
                <h3>{t('header.link1')}</h3>
              </a>
              <span style={{ display: 'block' }}></span>
            </li>
            <li style={{ borderLeftWidth: '0px' }}>
              <Link to="/auth">
                <a href="" style={{ color: 'white', textDecoration: 'none' }}>
                  <h3>{t('header.link2')}</h3>
                </a>
                <span style={{ display: 'block' }}></span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default HeaderComponent;



// import React from 'react';
// import { Link } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';

// const HeaderComponent = ({ changeLanguage }) => {
//   const { t, i18n } = useTranslation();

//   const switchLanguage = (lng) => {
//     i18n.changeLanguage(lng);
//     changeLanguage(lng);
//   };

//   return (
//     <div id="header">
//       <div id="header-content">
//         <a href="/">
//           {i18n.language === 'ru' ? (
//             <>
//               <h1 className="toph">{t('header.title')}</h1>
//               <h2 className="both">{t('header.subtitle')}</h2>
//             </>
//           ) : (
//             <>
//               <h2 className="toph">{t('header.titleKZ')}</h2>
//               <h1 className="both">{t('header.subtitleKZ')}</h1>
//             </>
//           )}
//         </a>

//         <div className="s_l">
//           <div className="lang">
//             <div id="jflanguageselection">
//               <form id="languageForm" className="form-hidden">
//                 <ul className="jflanguageselection">
//                   {i18n.language === 'kk' ? (
//                     <>
//                       <li id="active_language">
//                         <a
//                           id="linkKaz"
//                           href="#"
//                           onClick={(e) => {
//                             e.preventDefault();
//                             switchLanguage('kk');
//                           }}
//                         >
//                           <span lang="kz">KZ</span>
//                         </a>
//                       </li>
//                       <li>
//                         <a
//                           id="linkRus"
//                           href="#"
//                           onClick={(e) => {
//                             e.preventDefault();
//                             switchLanguage('ru');
//                           }}
//                         >
//                           <span lang="ru">RU</span>
//                         </a>
//                       </li>
//                     </>
//                   ) : (
//                     <>
//                       <li>
//                         <a
//                           id="linkKaz"
//                           href="#"
//                           onClick={(e) => {
//                             e.preventDefault();
//                             switchLanguage('kk');
//                           }}
//                         >
//                           <span lang="kz">KZ</span>
//                         </a>
//                       </li>
//                       <li id="active_language">
//                         <a
//                           id="linkRus"
//                           href="#"
//                           onClick={(e) => {
//                             e.preventDefault();
//                             switchLanguage('ru');
//                           }}
//                         >
//                           <span lang="ru">RU</span>
//                         </a>
//                       </li>
//                     </>
//                   )}
//                 </ul>
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div id="menu">
//         <div id="menu2">
//           <ul className="menu-main">
//             <li style={{ borderLeftWidth: '0px' }}>
//               <a href="https://pki.gov.kz/get-sdk/" style={{ color: 'white', textDecoration: 'none' }}>
//                 <h3>{t('header.link1')}</h3>
//               </a>
//               <span style={{ display: 'block' }}></span>
//             </li>
//             <li style={{ borderLeftWidth: '0px' }}>
//               <Link to="/auth">
//                 <a href="" style={{ color: 'white', textDecoration: 'none' }}>
//                   <h3>{t('header.link2')}</h3>
//                 </a>
//                 <span style={{ display: 'block' }}></span>
//               </Link>
//             </li>
//           </ul>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default HeaderComponent;
