// ErrorComponent.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const ErrorComponent = () => {
    const location = useLocation();
    const { error } = location.state || {};
    const {t} = useTranslation();
  
  return (
    <div className="content">
        <br />
        <br />
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
      <h1 style={{ color: '#e74c3c' }}>{t('error.mainError')}</h1>
      <p style={{ color: '#333', marginTop: '10px' }}>{error || t('error.defaultError')}</p>
    </div>
    </div>
  );
};

export default ErrorComponent;
